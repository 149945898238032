import { Stack } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { ProgressButton } from "~/components/ProgressButton";
import { SplitButton } from "~/components/SplitButton";
import { AutostoreStatusInfo } from "~/components/debug/AutostoreStatusInfo";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { selectStatusSelectedAutostoreGridId } from "~/redux/selectors/statusSelectors";
import {
  useGetAutostoreGridsQuery,
  useGetAutostoreTasksQuery
} from "~/redux/warehouse/autostoreGrid.hooks";
import { useGetWorkstationsQuery } from "~/redux/warehouse/workstation.hooks";

import { setSelectedAutostoreGridId } from "./status.slice";

export const PortStatus = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const selectedAutostoreGridId = useAppSelector(
    selectStatusSelectedAutostoreGridId
  );

  const { data: autostoreGrids } = useGetAutostoreGridsQuery();
  const { refetch: refetchAutostoreTasks } = useGetAutostoreTasksQuery(
    selectedAutostoreGridId || skipToken
  );

  const { data: workstations = [], error: getWorkstationsError } =
    useGetWorkstationsQuery();
  if (getWorkstationsError) {
    throw new Error(getMessageFromRtkError(getWorkstationsError));
  }

  const portsForSelectedGrid = (workstations || [])
    .filter(
      (workstation) => workstation.autostoreGridId === selectedAutostoreGridId
    )
    .map((workstation) => [
      ...new Map(
        workstation.ports.map((port) => [
          // we only need to show the parent id, not the children
          port.parentPortId || port.portId,
          workstation
        ])
      )
    ])
    .flat()
    // by default js sorts alphabetically...
    .sort((a, b) => a[0] - b[0]);

  const [forcePollingActive, setForcePollingActive] = useState<boolean>(false);

  return (
    <Stack direction={"column"} gap={5}>
      <Stack direction={"row"} gap={2} justifyContent={"flex-end"}>
        <SplitButton
          selectedValue={selectedAutostoreGridId}
          options={(autostoreGrids || []).map((autostoreGrid) => ({
            label: autostoreGrid.autostoreGridName,
            value: autostoreGrid.autostoreGridId
          }))}
          clickCb={(value) => dispatch(setSelectedAutostoreGridId(value))}
          switchCb={(value) => dispatch(setSelectedAutostoreGridId(value))}
        />
        <ProgressButton
          data-testid="fetch-autostore-tasks"
          buttonSize="medium"
          emphasis="medium"
          responsive
          variant="contained"
          color={forcePollingActive ? "secondary" : "primary"}
          onClick={(): void => {
            setForcePollingActive(!forcePollingActive);
          }}
        >
          {t("poll all ports")}
        </ProgressButton>
        <ProgressButton
          data-testid="fetch-autostore-tasks"
          buttonSize="medium"
          emphasis="medium"
          responsive
          variant="contained"
          color="primary"
          onClick={(): void => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
            refetchAutostoreTasks();
          }}
        >
          {t("refresh task groups")}
        </ProgressButton>
      </Stack>
      <Stack
        direction={"row"}
        gap={10}
        flexWrap={"wrap"}
        px={5}
        alignItems={"flex-start"}
      >
        {portsForSelectedGrid.map((port) => (
          <AutostoreStatusInfo
            key={`port-status-info-${port[0]}`}
            defaultAccordion="port state"
            portPollingIsActive={false}
            forceWorkstation={port[1]}
            forcePortId={port[0]}
            forcePolling={forcePollingActive || undefined}
            forceAutostoreId={selectedAutostoreGridId || undefined}
            updateColorDisabled
          />
        ))}
      </Stack>
    </Stack>
  );
};
