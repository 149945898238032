import Bulk from "@locaisolutions/icons/dist/icons20px/Bulk20Px";
import Warehouse from "@locaisolutions/icons/dist/icons20px/Warehouse20Px";
import AutostoreRobot from "@locaisolutions/icons/dist/icons24px/R5Robot24Px";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import { Stack, Box, Typography, useMediaQuery } from "@mui/material";
import { t } from "i18next";

import { useAppSelector } from "~/app/store";
import CircularProgressWithLabel from "~/components/CircularProgress";
import { BatchStatus, BatchType } from "~/features/batch/batch.type";
import { isExpressBatch } from "~/lib/helpers";

import { mobileWidth } from "~/lib/theme";

import { StatusChip } from "./StatusChip";
import { selectBatches } from "./batchCards.slice";

const BatchIcon = ({ batchType }: { batchType: BatchType }) => {
  return (
    <>
      {batchType?.toLowerCase() === "autostore" && (
        <Box aria-label="autostore-robot-icon">
          <AutostoreRobot />
        </Box>
      )}
      {batchType?.toLowerCase() === "warehouse" && (
        <Box aria-label="warehouse-icon">
          <Warehouse />
        </Box>
      )}
      {batchType?.toLowerCase() === "bulk" && (
        <Box aria-label="bulk-icon">
          <Bulk />
        </Box>
      )}
    </>
  );
};

const WorkstationBadge = ({ workstation }: { workstation: string }) => {
  return (
    <Stack alignItems="flex-start">
      <Typography
        variant="subtitle2"
        color="textSecondary"
        style={{ whiteSpace: "nowrap" }}
      >
        Assigned to:
      </Typography>
      <Typography>{workstation}</Typography>
    </Stack>
  );
};

export const BatchCardHeader = ({ batchId }: { batchId: Guid }) => {
  const batch = useAppSelector(selectBatches).find(
    (b) => b.batchId === batchId
  )!;

  const { batchType, batchName, deviceId, cartNumber, status } = batch;

  const isMobile = useMediaQuery(mobileWidth);

  return (
    <Stack direction="row" gap={3}>
      <Stack
        direction="row"
        spacing={1.5}
        alignItems="center"
        px={isMobile ? 1 : 2}
      >
        <BatchIcon batchType={batchType as BatchType} />
        <Stack alignItems="flex-start">
          <Typography>{batchType}</Typography>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            style={{ whiteSpace: "nowrap" }}
          >
            {batchName}
          </Typography>
        </Stack>
      </Stack>
      {isExpressBatch(batch.orderSummaries) && (
        <Stack direction="column" alignItems="center" justifyContent="center">
          <AccessTimeIcon fontSize="medium" />
          <Typography variant="caption" mt={-0.5}>
            {t("express")}
          </Typography>
        </Stack>
      )}
      <Box ml="auto" minWidth="100px">
        {!isMobile && deviceId ? (
          <WorkstationBadge workstation={deviceId} />
        ) : (
          <div />
        )}
      </Box>
      <Box
        display="grid"
        gridTemplateColumns={
          isMobile
            ? "repeat(3, min-content)"
            : "minmax(110px, 1fr) minmax(110px, 1fr) min-content"
        }
        gap={2}
        alignItems="center"
      >
        <StatusChip batchStatus={status as BatchStatus} />
        {cartNumber ? (
          <Typography>{`${t("cart")} ${cartNumber}`}</Typography>
        ) : (
          <div />
        )}
        <CircularProgressWithLabel
          aria-label="batch-circular-progress"
          completeCount={batch.completedPicks}
          cancelledCount={batch.canceledPicks}
          totalCount={batch.totalPicks}
          showBatchPicks
          style={{ marginTop: 6 }}
        />
      </Box>
    </Stack>
  );
};
