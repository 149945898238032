import {
  Automatic20Px,
  Cart20Px,
  ManualPick20Px,
  Play20Px,
  Print20Px,
  Requirement20Px,
  Workstation20Px
} from "@locaisolutions/icons";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { LoadingButton } from "@mui/lab";
import { IconButton, Typography, useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";

import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { ConfirmationModal } from "~/components/ConfirmationModal";
import { NavBarButtonGroup, UnselectButton } from "~/components/navbar/Navbar";

import { BatchStatus } from "~/features/batch/batch.type";
import { useToast } from "~/hooks/useToast";
import { canBatchBePicked } from "~/lib/fcConfigHelpers";
import { canPrepBatchOption } from "~/lib/helpers";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { downloadBlob } from "~/lib/shared";
import { mobileWidth } from "~/lib/theme";
import { getBatchLabels } from "~/redux/actions/labels";
import { selectClientConfig } from "~/redux/selectors/siteSelectors";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";
import {
  usePostForcePrepareCartMutation,
  usePostUnsuspendBatchMutation
} from "~/redux/warehouse/batches.hooks";

import { useGetWorkstationsQuery } from "~/redux/warehouse/workstation.hooks";

import { ChangeWorkstationModal } from "./ChangeWorkstationModal";
import { PickBatchButton } from "./PickBatchButton";
import { resetSelectedBatch } from "./adminBatches.slice";

import { shouldShowPickBatchButton } from "./shouldShowBatchPickButton";

export function AdminBatchesToolbar() {
  const isMobile = useMediaQuery(mobileWidth, { noSsr: true });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { errorToast, successToast } = useToast();
  const dispatch = useAppDispatch();

  const selectedBatch = useAppSelector(
    (state) => state.adminBatches.selectedBatch
  );

  const selectedFulfillmentCenter = useAppSelector(
    selectUsersFulfillmentCenter
  );
  const {
    bat_printEnabled,
    cartPrepEnabled,
    bat_pickFromBatchesEnabled,
    bat_stageFromBatchesEnabled
  } = useAppSelector(selectClientConfig);

  const { data: workstations = [], error: getWorkstationsError } =
    useGetWorkstationsQuery();
  if (getWorkstationsError) {
    throw new Error(getMessageFromRtkError(getWorkstationsError));
  }

  const [mobileMoreMenuEl, setMobileMoreMenuEl] = useState<null | HTMLElement>(
    null
  );
  const [isWorkstationModalOpen, setIsWorkstationModalOpen] = useState(false);
  const [changeStatusModalOpen, setChangeStatusModalOpen] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);

  const [postUnsuspend, { isLoading: isUnsuspending }] =
    usePostUnsuspendBatchMutation();
  const [postForcePrepareCart] = usePostForcePrepareCartMutation();

  const workstation = useAppSelector(selectThisWorkstation);
  const showPickBatchButton =
    selectedBatch &&
    !isMobile && // mobile version of the page is not at a workstation
    shouldShowPickBatchButton(
      selectedBatch.batchType,
      selectedBatch.status as BatchStatus,
      workstation,
      selectedBatch.deviceId,
      cartPrepEnabled
    );

  const canPickBatch =
    selectedFulfillmentCenter &&
    selectedBatch &&
    canBatchBePicked(selectedFulfillmentCenter, selectedBatch) &&
    ["scheduled", "cart ready", "processing"].includes(
      selectedBatch.status?.toLowerCase() || ""
    );

  const canStageBatch = ["picked", "dropped"].includes(
    (selectedBatch && selectedBatch.status?.toLowerCase()) || ""
  );
  const canPrepBatch = canPrepBatchOption(selectedBatch);

  const handlePrintBatchLabels = useCallback(async () => {
    setIsPrinting(true);
    if (selectedBatch) {
      const labelData = await dispatch(getBatchLabels(selectedBatch.batchId));

      if (labelData) {
        downloadBlob(labelData, `${selectedBatch.batchName}.labels.zpl`);
      }
    }
    setIsPrinting(false);
  }, [dispatch, selectedBatch]);

  const handleUnsuspendClick = async (): Promise<void> => {
    try {
      if (selectedBatch) {
        await postUnsuspend({
          batchId: selectedBatch.batchId,
          autostoreGridId: selectedBatch.autostoreGridId
        }).unwrap();
        successToast("Unsuspended Batch", {
          description: `${selectedBatch.batchName} was unsuspended`
        });
      }
    } catch (err: unknown) {
      errorToast(getMessageFromRtkError(err));
    }
  };

  const handleConfirmClick = async () => {
    try {
      if (selectedBatch) {
        setChangeStatusModalOpen(false);
        await postForcePrepareCart({
          batchId: selectedBatch.batchId,
          cartNumber: selectedBatch.cartNumber
        }).unwrap();

        successToast("Batch Status Updated", {
          description: `${selectedBatch.batchName} now has status Cart Ready`
        });
      }
    } catch (err: unknown) {
      errorToast(getMessageFromRtkError(err));
    }
  };

  const contextOptions = [
    bat_printEnabled && selectedBatch && (
      <LoadingButton
        key="batch labels"
        variant="text"
        color="secondary"
        onClick={handlePrintBatchLabels}
        startIcon={<Print20Px fill="#fff" />}
        loading={isPrinting}
      >
        {t("batch labels")}
      </LoadingButton>
    ),

    selectedBatch &&
      selectedBatch.batchType !== "Bulk" &&
      cartPrepEnabled &&
      canPrepBatch && (
        <Button
          key="prep"
          color="secondary"
          onClick={(): void =>
            navigate(`/batches/${selectedBatch.batchName}/cart-prep`)
          }
          startIcon={<Cart20Px fill="#fff" />}
        >
          <Typography variant="body2" style={{ color: "#fff" }}>
            {t("prep")}
          </Typography>
        </Button>
      ),
    selectedBatch &&
      selectedBatch.batchType !== "Autostore" &&
      canPickBatch &&
      bat_pickFromBatchesEnabled && (
        <Button
          key="pick"
          color="secondary"
          onClick={(): void =>
            navigate(`/batches/${selectedBatch.batchName}/pick`)
          }
          startIcon={<ManualPick20Px fill="#fff" />}
        >
          <Typography variant="body2" style={{ color: "#fff" }}>
            {t("pick verb")}
          </Typography>
        </Button>
      ),
    selectedBatch && canStageBatch && bat_stageFromBatchesEnabled && (
      <Button
        key="stage"
        color="secondary"
        onClick={(): void =>
          navigate(`/batches/${selectedBatch.batchName}/stage`)
        }
        startIcon={<Requirement20Px fill="#fff" />}
      >
        <Typography variant="body2" style={{ color: "#fff" }}>
          {t("stage_verb")}
        </Typography>
      </Button>
    ),
    showPickBatchButton && (
      <PickBatchButton batchId={selectedBatch.batchId} key={"pickbatch"} />
    )
  ];

  const moreContextButtons = [
    selectedBatch && selectedBatch.status === "Suspended" && (
      <Button
        key="unsuspend"
        onClick={async () => {
          setMobileMoreMenuEl(null);
          await handleUnsuspendClick();
        }}
        disabled={isUnsuspending}
        startIcon={<Play20Px fill="#fff" />}
      >
        <Typography
          variant="body2"
          sx={{ color: isMobile ? "primary.main" : "#fff" }}
        >
          {t("unsuspend")}
        </Typography>
      </Button>
    ),
    selectedBatch && selectedBatch.status === "Suspended" && (
      <Button
        key="change workstation"
        onClick={() => {
          setMobileMoreMenuEl(null);

          setIsWorkstationModalOpen(true);
        }}
        startIcon={<Workstation20Px fill="#fff" />}
      >
        <Typography
          variant="body2"
          sx={{ color: isMobile ? "primary.main" : "#fff" }}
        >
          {t("change workstation")}
        </Typography>
      </Button>
    ),
    selectedBatch && selectedBatch.status === "Waiting Dependency" && (
      <Button
        key="change status"
        onClick={(): void => {
          setMobileMoreMenuEl(null);

          setChangeStatusModalOpen(true);
        }}
        startIcon={<Automatic20Px fill="#fff" />}
      >
        <Typography
          variant="body2"
          sx={{ color: isMobile ? "primary.main" : "#fff" }}
        >
          {t("change status")}
        </Typography>
      </Button>
    )
  ];

  const desktopContextOptions = [...contextOptions, ...moreContextButtons];
  const mobileMenuOptions = moreContextButtons.filter((el) => !!el);

  return (
    <>
      <Toolbar data-testid="batches-navbar-context">
        <UnselectButton onClick={() => dispatch(resetSelectedBatch())} />
        <NavBarButtonGroup>
          {isMobile ? contextOptions : desktopContextOptions}
        </NavBarButtonGroup>
        {isMobile && mobileMenuOptions.length > 0 && (
          <>
            <IconButton
              id="navbar-more-menu-button"
              onClick={(e): void => setMobileMoreMenuEl(e.currentTarget)}
              edge="end"
              data-testid="more-button"
              size="large"
              style={{ color: "white" }}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="navbar-more-menu"
              anchorEl={mobileMoreMenuEl}
              keepMounted
              open={!!mobileMoreMenuEl}
              onClose={() => setMobileMoreMenuEl(null)}
            >
              {mobileMenuOptions.map((menuItem, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={`menu-item-${i}`}>{menuItem}</li>
              ))}
            </Menu>
          </>
        )}
      </Toolbar>
      {isWorkstationModalOpen && (
        <ChangeWorkstationModal
          currentWorkstation={(selectedBatch && selectedBatch.deviceId) || ""}
          batchId={(selectedBatch && selectedBatch.batchId) || ""}
          workstations={
            selectedBatch
              ? workstations.filter(
                  (workstation) =>
                    workstation.autostoreGridId ===
                    selectedBatch.autostoreGridId
                )
              : workstations
          }
          setIsWorkstationModalOpenCallback={setIsWorkstationModalOpen}
        />
      )}
      <ConfirmationModal
        isOpen={changeStatusModalOpen}
        confirmCb={async () => {
          await handleConfirmClick();
        }}
        closeCb={() => setChangeStatusModalOpen(false)}
        modalTitle={t("change batch status?")}
        modalText={t("change batch status note")}
      />
    </>
  );
}
