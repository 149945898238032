import { Stack, Typography, useTheme } from "@mui/material";

import { AutostoreRobotSvg } from "~/icons/AutostoreRobotSvg";

import { BouncingDotsLoader } from "./BouncingDotsLoader";
import { Center } from "./Center";
import { RobotLoader } from "./RobotLoader";

type Props = {
  loadingMessage?: string;
};

/**
 * Displays a loading animation and message to a user as a placeholder for a
 * full sized page.
 *
 * @param loadingMessage
 * @constructor
 */
export function LoadingPage({ loadingMessage }: Props) {
  const { palette } = useTheme();
  return (
    <Center>
      <Stack spacing={8} marginTop="25vh">
        <RobotLoader>
          <AutostoreRobotSvg
            sx={{ fontSize: "2rem", color: palette.autostoreRed.main }}
          />
          <BouncingDotsLoader sx={{ div: { height: 2, width: 2 } }} />
        </RobotLoader>
        {loadingMessage && (
          <Typography variant="h5" textAlign="center">
            {loadingMessage}
          </Typography>
        )}
      </Stack>
    </Center>
  );
}
