import { WarningRounded } from "@mui/icons-material";
import { useMediaQuery, Pagination, Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { useNavigate } from "react-router";

import { getUserClientId } from "~/api/usersTypes/auth0Profile";
import { useAppDispatch, useAppSelector } from "~/app/store";
import { ErrorPanel } from "~/components/ErrorPanel";
import { ErrorSuccessSnackbar } from "~/components/ErrorSuccessSnackbar";
import IconBulkBox, { IconBoxTempZone } from "~/components/IconBulkBox";
import ScanningIndicator, {
  useScanIndicator
} from "~/components/ScanningIndicator";
import AutostoreTable from "~/components/autostore/table/AutostoreTable";
import { useNavbar } from "~/hooks/useNavbar";
import { useView } from "~/hooks/useView";
import { useBarcodeScanner, useKeyDownHandler } from "~/lib/barCodeScan";
import { formatUtcDate } from "~/lib/dateHelpers";
import { searchProduct, getBarcodeValue } from "~/lib/helpers";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { mobileWidth } from "~/lib/theme";
import {
  clearErrorMessage,
  clearSuccessMessage
} from "~/redux/actions/putaway";
import { StoreState } from "~/redux/reducers";
import { useGetPutawayTasksWithPagingQuery } from "~/redux/warehouse/putAwayTasks.hooks";
import { PutAwayTaskSummaryDto } from "~/types/api";

import { PutawaySearch } from "./PutawaySearch";
import { PutawayToolbar } from "./PutawayToolbar";
import { selectPutawayTasks, setPage, setSearch } from "./putaway.slice";

const mapStateToProps = (state: StoreState) => ({
  error: state.putaway.error,
  success: state.putaway.successMessage,
  clientId: state.login.profile ? getUserClientId(state.login.profile) : null
});

const connector = connect(mapStateToProps, {
  clearErrorMessage,
  clearSuccessMessage
});
type PutawayInheritedProps = { viewTitle?: string };
type PropsFromRedux = ConnectedProps<typeof connector>;
export type PutawayProps = PropsFromRedux & PutawayInheritedProps;

export function Putaway({
  clearErrorMessage,
  clearSuccessMessage,
  success,
  error: reduxError,
  viewTitle,
  clientId
}: PutawayProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const navigate = useNavigate();

  useView({ permanentSidenav: true, fixedHeight: true });
  const { setMenuItems, setToolbar } = useNavbar({
    centerComponent: useMemo(() => <PutawaySearch />, []),
    viewTitle
  });

  const page = useAppSelector((state) => state.putawaySlice.page);
  const searchText = useAppSelector((state) => state.putawaySlice.search);
  const selectedPutawayTasks = useAppSelector(
    (state) => state.putawaySlice.selectedPutawayTasks
  );

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const isMobile = useMediaQuery(mobileWidth);

  const limit = isMobile ? 3 : 12;

  const { data, isFetching, error, refetch } =
    useGetPutawayTasksWithPagingQuery(
      {
        status: "scheduled",
        limit,
        offset: (page - 1) * limit,
        ...(searchText && { search: searchText })
      },
      { refetchOnMountOrArgChange: true }
    );

  const putawayInventory = data?.putAwayTasks;
  const putawayInventoryCount = data?.putAwayTasksCount;

  const totalPageCount = putawayInventoryCount
    ? Math.ceil(putawayInventoryCount / limit)
    : 0;

  const [scanState, setScanState] = useScanIndicator();
  const [scannedBarcode, setScannedBarcode] = useState<string | null>(null);

  useKeyDownHandler();
  useBarcodeScanner({
    findScanMatch: (buffer: string) => {
      // product scan
      setScanState("success");
      setScannedBarcode(buffer);
      const barcodeValue = getBarcodeValue(buffer);
      dispatch(setPage(1));
      dispatch(setSearch(barcodeValue));
      return barcodeValue;
    },
    processScanMatch: () => null
  });

  // if there are no putaway tasks returned, check backend to see if the product exists at all
  useEffect(() => {
    if (putawayInventory?.length === 0 && scannedBarcode) {
      const searchProductFunc = async () => {
        const hits = await searchProduct(scannedBarcode);
        if (!hits.length) {
          setErrorMessage(t("scanned product does not exist"));
        }
      };
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      searchProductFunc();
    }
  }, [clientId, putawayInventory, scannedBarcode, t]);

  useEffect(() => {
    setMenuItems([
      {
        textContent: "Refresh",
        actionCb: async () => {
          await refetch();
        }
      }
    ]);
  }, [navigate, refetch, setMenuItems]);

  useEffect(() => {
    if (selectedPutawayTasks.length > 0) {
      setToolbar(<PutawayToolbar limit={limit} />);
    } else {
      setToolbar(null);
    }
  }, [limit, selectedPutawayTasks.length, setToolbar]);

  const sortedPutawayInventory = putawayInventory?.slice().sort((a, b) => {
    // prioritize non-backstock bins if isHighPriority is the same
    if (
      a.target &&
      b.target &&
      a.target.isHighPriority === b.target.isHighPriority
    ) {
      return a.target.bin.binType !== "back stock" ? -1 : 0;
    }
    // otherwise, prioritize high priority
    return a.target && a.target.isHighPriority ? -1 : 0;
  });

  const tasksItemCount = sortedPutawayInventory?.reduce(
    (acc: number, i: PutAwayTaskSummaryDto) => acc + i.remaining.value,
    0
  );

  const handlePutAwayRowClick = (putAwayTaskId: string) => {
    dispatch(
      selectPutawayTasks(
        selectedPutawayTasks.includes(putAwayTaskId)
          ? [...selectedPutawayTasks.filter((id) => id !== putAwayTaskId)]
          : [...selectedPutawayTasks, putAwayTaskId]
      )
    );
  };

  return (
    <Box
      style={{
        marginTop: 20,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "nowrap",
        minHeight: "100%",
        height: "100%",
        width: "100%",
        flexDirection: "column"
      }}
    >
      {!!error && <ErrorPanel message={getMessageFromRtkError(error)} />}
      {!error && (
        <>
          <Box
            style={{
              width: isMobile ? "100%" : "95%",
              height: "70%"
            }}
          >
            {sortedPutawayInventory && (
              <AutostoreTable<PutAwayTaskSummaryDto>
                widthOfCols={
                  !isMobile
                    ? ["52%", "12%", "12%", "12%", "12%"]
                    : ["52%", "28%", "20%"]
                }
                bodyVariant="body2"
                headerColNames={[
                  t("product"),
                  t("target bin"),
                  !isMobile ? t("received date") : null,
                  !isMobile ? t("purchase order") : null,
                  `(${tasksItemCount}) ${t("items")}`
                ].filter((el) => el)}
                rowId={(row: PutAwayTaskSummaryDto) => row.putAwayTaskId}
                renderColumns={[
                  (row: PutAwayTaskSummaryDto) => {
                    const { isHighPriority } = row.target || {};
                    const { name } = row.product || {};

                    return (
                      <Box>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography variant="body2">{name}</Typography>
                          </Grid>
                          {isMobile && (
                            <>
                              <Grid item xs={12}>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {formatUtcDate(row.createdTimestamp)}
                                </Typography>
                              </Grid>
                              {row.purchaseOrderLineItem
                                ?.purchaseOrderNumber && (
                                <Grid item xs={12}>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    {
                                      row.purchaseOrderLineItem
                                        .purchaseOrderNumber
                                    }
                                  </Typography>
                                </Grid>
                              )}
                            </>
                          )}
                        </Grid>
                        {isHighPriority && (
                          <WarningRounded
                            sx={{
                              color: "warning.main",
                              verticalAlign: "bottom"
                            }}
                          />
                        )}
                      </Box>
                    );
                  },
                  (row: PutAwayTaskSummaryDto) => {
                    const {
                      aisle = "",
                      shelfBay = "",
                      shelf = "",
                      position = "",
                      temperatureZone,
                      binId,
                      binType
                    } = row.target?.bin || {};

                    const isBulkTarget = binType === "Bulk";

                    return (
                      <Grid container alignItems="center">
                        <Grid item style={{ width: "100%" }}>
                          <Typography variant="body2">
                            {[aisle, shelfBay, shelf, position]
                              .filter(Boolean)
                              .join("-")}
                          </Typography>
                        </Grid>
                        {isBulkTarget && (
                          <Grid item style={{ marginLeft: 2 }}>
                            <Typography variant="body2">
                              {IconBulkBox(
                                (temperatureZone?.toLowerCase() ||
                                  "none") as IconBoxTempZone,
                                binId || "none"
                              )}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    );
                  },
                  !isMobile
                    ? (row: PutAwayTaskSummaryDto) =>
                        `${formatUtcDate(row.createdTimestamp) || ""}`
                    : null,
                  !isMobile
                    ? (row: PutAwayTaskSummaryDto) =>
                        `${
                          row.purchaseOrderLineItem?.purchaseOrderNumber || ""
                        }`
                    : null,
                  (row: PutAwayTaskSummaryDto) => `${row.remaining.value}`
                ].filter((el) => el)}
                rowData={sortedPutawayInventory}
                selectedRows={selectedPutawayTasks}
                selectRowCallback={(row: PutAwayTaskSummaryDto) => {
                  handlePutAwayRowClick(row.putAwayTaskId);
                }}
                loading={isFetching}
                noResults={
                  sortedPutawayInventory && sortedPutawayInventory.length === 0
                }
              />
            )}
          </Box>
          {totalPageCount > 1 && (
            <Grid container justifyContent="center" style={{ marginTop: 16 }}>
              <Pagination
                count={totalPageCount}
                page={page}
                onChange={(_e, p) => {
                  dispatch(setPage(p));
                  if (window.scrollTo) window.scrollTo(0, 0);
                }}
                shape="rounded"
              />
            </Grid>
          )}
        </>
      )}
      <ErrorSuccessSnackbar
        successMessage={success}
        errorMessage={reduxError || errorMessage}
        clearErrorMessage={() => {
          clearErrorMessage();
          setErrorMessage(null);
        }}
        clearSuccessMessage={() => clearSuccessMessage()}
      />
      <ScanningIndicator
        scanState={scanState}
        scannedBarcode={scannedBarcode}
        placeholderText="Scan Product"
      />
    </Box>
  );
}

export default connector(Putaway);
