import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { ErrorPanel } from "~/components/ErrorPanel";
import MultiPort from "~/components/autostore/autostoreBin/MultiPort";

import {
  selectNextBinInventoryByPort,
  selectPortStateByPort
} from "~/redux/selectors/autostoreSelectors";
import {
  selectMultiportSelectedCompartment,
  selectSelectedSummaries
} from "~/redux/selectors/inventorySelectors";
import {
  selectNextEmptyBinByPort,
  selectThisWorkstation
} from "~/redux/selectors/workstationsSelectors";

import {
  setIsInventoryAdjustDialogOpen,
  setSelectedAdjustingSummary
} from "./inventory.slice";

export function InventoryMultiPort() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentSelectedBinNumber = useAppSelector(
    (state) => state.inventoryNew.selectedAdjustingSummary?.autostoreBinNumber
  );
  const isGetBinsLoading = useAppSelector(
    (state) => state.inventoryNew.isGetBinsLoading
  );
  const nextBinInventoryByPort = useAppSelector(selectNextBinInventoryByPort);
  const nextEmptyBinByPort = useAppSelector(selectNextEmptyBinByPort);
  const portStateByPort = useAppSelector(selectPortStateByPort);
  const siteWorkstation = useAppSelector(selectThisWorkstation);
  const selectedCompartment = useAppSelector(
    selectMultiportSelectedCompartment
  );
  const selectedSummaries = useAppSelector(selectSelectedSummaries);

  return siteWorkstation ? (
    <MultiPort
      pickQuantity={0}
      nextBinInventoryByPort={nextBinInventoryByPort}
      nextEmptyBinByPort={nextEmptyBinByPort}
      portStateByPort={portStateByPort}
      workstation={siteWorkstation}
      currentSelectedBinId={currentSelectedBinNumber}
      selectedCompartment={selectedCompartment}
      showLoading={isGetBinsLoading}
      onBinClick={(compartment, binId) => {
        const selectedCompartment = (compartment || 0) + 1;
        const matchingSummariesByBin = selectedSummaries.filter(
          (row) => row.autostoreBinNumber === binId
        );
        const matchingSummaryByCompartment = matchingSummariesByBin.find(
          (summary) =>
            summary.autostoreCompartmentNumber === selectedCompartment
        );
        if (matchingSummariesByBin.length) {
          dispatch(
            setSelectedAdjustingSummary(
              matchingSummaryByCompartment || matchingSummariesByBin[0]
            )
          );
          dispatch(setIsInventoryAdjustDialogOpen(true));
        }
      }}
    />
  ) : (
    <ErrorPanel message={t("no autostore port selected")} flex="1 1 100%" />
  );
}
